function validate(values) {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.address) {
    errors.address = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (ValidateEmail(values.email) !== true) {
    errors.email = "Not valid";
  }
  if (!values.phone) {
    errors.phone = "Required";
  } else if (phonenumber(values.phone) !== true) {
    errors.phone = "Not valid";
  }
  if (!values.message) {
    errors.message = "Required";
  }
  return errors;
}

function ValidateEmail(mail) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

function phonenumber(phone) {
  return !!phone.match(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
  );
}

export default validate;
