import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import validate from "./helpers/validation";
import sendMail from "./helpers/sendMail";
import styles from "./styles";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      sending: false,
      submitSuccess: null,
      snackbarOpen: false,
    };
    this.state = this.initialState;
  }

  handleSubmit = async (values) => {
    try {
      if (this.state.sending === true) return;
      this.setState({
        sending: true,
        snackbarOpen: false,
      });
      const success = await sendMail(values);
      this.setState({
        sending: false,
        submitSuccess: success,
        snackbarOpen: true,
      });
    } catch (e) {
      this.setState({
        sending: false,
        submitSuccess: false,
        snackbarOpen: true,
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.closeSnackbar();
  };

  closeSnackbar = () => this.setState({ snackbarOpen: false });

  render() {
    const { classes, texts } = this.props;
    return (
      <div>
        <Snackbar
          open={this.state.snackbarOpen === true}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.submitSuccess === true ? "success" : "error"}
          >
            {this.state.submitSuccess === true
              ? "Mesajınızı aldık!"
              : "Mesajınızı gönderirken bir sorun ile karşılaşıldı!"}
          </Alert>
        </Snackbar>
        <Form
          onSubmit={this.handleSubmit}
          validate={validate.bind(this)}
          render={({ handleSubmit, pristine, invalid, submitting, form }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormControl fullWidth className={classes.line}>
                <InputLabel>{texts.name}</InputLabel>
                <Field name="name">
                  {({ input }) => (
                    <Input
                      id="name"
                      type="text"
                      inputProps={{
                        ...input,
                        autoComplete: "off",
                      }}
                      value={input.value}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl fullWidth className={classes.line}>
                <InputLabel>{texts.address}</InputLabel>
                <Field name="address">
                  {({ input }) => (
                    <Input
                      id="address"
                      type="text"
                      inputProps={{
                        ...input,
                        autoComplete: "off",
                      }}
                      value={input.value}
                    />
                  )}
                </Field>
              </FormControl>
              <div className={classes.fieldsContainer}>
                <FormControl fullWidth className={classes.field}>
                  <InputLabel>{texts.email}</InputLabel>
                  <Field name="email">
                    {({ input, meta }) => (
                      <Input
                        id="email"
                        type="email"
                        inputProps={{
                          ...input,
                          autoComplete: "off",
                        }}
                        value={input.value}
                        meta={meta}
                        error={
                          !meta.pristine &&
                          meta.touched &&
                          !meta.active &&
                          meta.error
                        }
                      />
                    )}
                  </Field>
                </FormControl>

                <FormControl fullWidth className={classes.field}>
                  <InputLabel>{texts.phone}</InputLabel>
                  <Field name="phone">
                    {({ input, meta }) => (
                      <Input
                        id="phone"
                        type="tel"
                        inputProps={{
                          ...input,
                          autoComplete: "off",
                        }}
                        value={input.value}
                        meta={meta}
                        error={
                          !meta.pristine &&
                          meta.touched &&
                          !meta.active &&
                          meta.error
                        }
                      />
                    )}
                  </Field>
                </FormControl>
              </div>
              <FormControl fullWidth className={classes.line}>
                <Field name="message">
                  {({ input }) => (
                    <TextField
                      id="message"
                      inputProps={{
                        ...input,
                        autoComplete: "off",
                      }}
                      value={input.value}
                      label={texts.message}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl className={classes.line}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={pristine || invalid || this.state.sending}
                >
                  {texts.submit}
                </Button>
              </FormControl>
            </form>
          )}
        />
      </div>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
  texts: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
