import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import Swiper from "react-id-swiper";
import img1 from "./../../assets/images/brands/1.png";
import img2 from "./../../assets/images/brands/2.png";
import img3 from "./../../assets/images/brands/3.png";
import img4 from "./../../assets/images/brands/4.png";
import img5 from "./../../assets/images/brands/5.png";
import img6 from "./../../assets/images/brands/6.png";
import img7 from "./../../assets/images/brands/7.png";
import img8 from "./../../assets/images/brands/8.png";
import img9 from "./../../assets/images/brands/9.png";
import img10 from "./../../assets/images/brands/10.png";
import img11 from "./../../assets/images/brands/11.png";
import img12 from "./../../assets/images/brands/12.png";
import img13 from "./../../assets/images/brands/13.png";
import img14 from "./../../assets/images/brands/14.png";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
];

const params = {
  slidesPerView: "auto",
  spaceBetween: 100,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 2000,
  },
};

const Brands = ({ classes, texts }) => {
  return (
    <div className={classes.root}>
      <Swiper {...params}>
        {images.map((src, i) => (
          <div
            key={"brands" + i}
            className={classes.container}
            style={{ backgroundImage: `url(${src})` }}
          ></div>
        ))}
      </Swiper>
    </div>
  );
};

Brands.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Brands);
