import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Slide, Fade } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import transtarLogo from "./../../assets/images/transtar.png";
const content = {
  tr: {
    title: "kaynak kardesler",
    subTitle: "otomatik şanzımana dair herşey",
    welcome: "hoşgeldiniz",
    logo: "türkiye mümessili",
  },
  en: {
    title: "kaynak kardesler",
    subTitle: "the automatic transmission parts that you are looking for",
    welcome: "welcome",
    logo: "turkey distributor",
  },
};

let timeout;

const Banner = ({ classes, language, width, greeted, setGreeted }) => {
  const [title, setTitle] = useState(greeted);
  if (!greeted) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setGreeted(true);
      setTimeout(() => setTitle(true), 1500);
    }, 3000);
  }

  const texts = content[language];
  return (
    <div className={classes.root}>
      <Slide direction={"down"} timeout={1500} in>
        <div className={classes.logo}>
          <div
            className={classes.logoImage}
            style={{ backgroundImage: `url(${transtarLogo})` }}
          />
          <Typography
            variant="body2"
            color="inherit"
            className={classes.logoText}
          >
            {texts.logo}
          </Typography>
        </div>
      </Slide>
      <div className={classes.banner}></div>
      {isWidthUp("md", width) ? (
        <Slide direction={"up"} timeout={1500} in>
          <div className={classes.overlay}>
            <div className={classes.content}>
              <Fade in={!greeted} timeout={1500}>
                <div className={classes.greeting}>
                  <Typography variant="h1" color="inherit">
                    {texts.welcome}
                  </Typography>
                </div>
              </Fade>
              <Fade in={title && greeted} timeout={{ enter: 1500, exit: 0 }}>
                <div>
                  <Typography
                    variant="h1"
                    color="inherit"
                    className={classes.cinzel}
                  >
                    {texts.title}
                  </Typography>
                  <Typography variant="h6" color="inherit">
                    {texts.subTitle}
                  </Typography>
                </div>
              </Fade>
            </div>
          </div>
        </Slide>
      ) : (
        <div className={classes.overlay}>
          <div className={classes.content}>
            <Fade in={!greeted} timeout={1500}>
              <div className={classes.greeting}>
                <Typography variant="h1" color="secondary">
                  {texts.welcome}
                </Typography>
              </div>
            </Fade>
            <Fade in={title && greeted} timeout={{ enter: 1500, exit: 0 }}>
              <div>
                <Typography
                  variant="h1"
                  color="secondary"
                  className={classes.cinzel}
                >
                  {texts.title}
                </Typography>
                <Typography variant="h6" color="inherit">
                  {texts.subTitle}
                </Typography>
              </div>
            </Fade>
          </div>
        </div>
      )}
    </div>
  );
};

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  greeted: PropTypes.bool.isRequired,
};

export default withWidth()(withStyles(styles)(Banner));
