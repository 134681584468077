import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid, CardMedia } from "@material-ui/core";
import ContactForm from "./../ContactForm";

const content = {
  tr: {
    title: "Bize ulaşın",
    subTitle: "adresimiz",
    formLabels: {
      name: "ad",
      address: "adres",
      email: "email",
      phone: "telefon",
      message: "mesajınızı yazın...",
      submit: "gönder",
    },
  },
  en: {
    title: "contact us",
    subTitle: "find us",
    formLabels: {
      name: "name",
      address: "address",
      email: "email",
      phone: "phone",
      message: "type your message here",
      submit: "submit",
    },
  },
};

const Contact = ({
  classes,
  language,
  stores,
  setStore,
  storeIndex,
  mediaComponent,
}) => {
  const store = stores[storeIndex];
  const texts = content[language];
  return (
    <div className={classes.root}>
      <div className={classes.boxContainer}>
        <div className={classes.box}>
          <Typography variant="h2" color="secondary">
            {texts.title}
          </Typography>
          <ContactForm texts={texts.formLabels} />
        </div>
        <div className={classes.box} id="stores">
          <Grid
            container
            justify="space-between"
            alignItems="center"
            direction="column"
            style={{ height: "100%" }}
            spacing={3}
          >
            <Grid item>
              <Typography variant="h2" color="secondary">
                {texts.subTitle}
              </Typography>
              {stores.length > 1 && (
                <div>
                  {stores.map((s, i) => (
                    <Button
                      className={classes.button}
                      disabled={storeIndex === i}
                      color="secondary"
                      variant="outlined"
                      key={"store_" + i}
                      onClick={() => setStore(i)}
                    >
                      {s.name}
                    </Button>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item>
              <Typography className={classes.text} variant="body2">
                {store.address}
              </Typography>
              <Typography className={classes.text} variant="body2">
                {store.email}
              </Typography>
              <Typography className={classes.text} variant="body2">
                {store.phone}
              </Typography>
            </Grid>
            {mediaComponent && <Grid item>{mediaComponent}</Grid>}
          </Grid>
        </div>
      </div>
      <CardMedia
        className={classes.map}
        component="iframe"
        src={store.iframe}
      />
    </div>
  );
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  storeIndex: PropTypes.number.isRequired,
  stores: PropTypes.array.isRequired,
  setStore: PropTypes.func.isRequired,
  mediaComponent: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
