import url from "./endpoint";

const sendRequest = (body) =>
  new Promise((res, rej) => {
    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((r) => {
        res(true);
      })
      .catch((e) => {
        rej();
      });
  });

export default async (values) => {
  try {
    await sendRequest(values);
    return true;
  } catch (e) {
    return false;
  }
};
